import React from 'react';
import { ShowSnackbar } from '../types/snackbar';
import { LogLevel, Logger } from '../classes/log';
import { Profiling, Telemetry } from '../classes/telemetry';

export interface AppContextConfig {
  logLevel: LogLevel;
  profiling: Profiling;
  eventTracking: boolean;
  loggerHistorySize: number;
  timeout: number | false;
}

export interface AppContext {
  logger: Logger;
  telemetry: Telemetry;
  showSnackbar: ShowSnackbar;
  config: AppContextConfig;
  id: string;
  breadcrumbs: string[];
}

export const appContext_INTERNAL = React.createContext<AppContext | null>(null);
