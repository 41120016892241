/* eslint-disable @typescript-eslint/no-unused-vars */
import { AppContextConfig } from '../contexts/appContext';
import { Logger } from './log';

// CONSTANTS

const PROFILING_DESCRIPTORS = ['event', 'component', 'effect'] as const;

// TYPES

type ProfilingDescriptor = (typeof PROFILING_DESCRIPTORS)[number];

export type Profiling = boolean | ProfilingDescriptor[];

export function combineProfilingValues(
  profiling1: Profiling,
  profiling2: Profiling
): Profiling {
  // if any of those are true, it should be true
  if (profiling1 === true || profiling2 === true) {
    return true;
  }
  // if profiling1 is false return whatever profiling2 is
  if (profiling1 === false) {
    return profiling2;
  }
  // if profiling2 is false return whatever profling1 is
  if (profiling2 === false) {
    return profiling1;
  }

  // if they both are array, return the deduped union of them
  return [...profiling1, ...profiling2].filter(
    (value, index, array) => index === array.indexOf(value)
  );
}

export function assertProfilingValue(value: unknown): Profiling {
  if (typeof value === 'boolean') {
    return value;
  }
  if (PROFILING_DESCRIPTORS.includes(value as any)) {
    return [value] as Profiling;
  }
  if (
    Array.isArray(value) &&
    value.every((entry) => PROFILING_DESCRIPTORS.includes(entry))
  ) {
    return value as Profiling;
  }
  throw new Error(`${value} is not a valid profiling value`);
}

export type TelemetryConstructor = {
  new (logger: Logger, config: AppContextConfig): Telemetry;
};

export type User = {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  organizationId: number;
};

export abstract class Telemetry {
  constructor(protected logger: Logger, protected config: AppContextConfig) {
    this.captureException = this.captureException.bind(this);
    this.trackEvent = this.trackEvent.bind(this);
    this.identify = this.identify.bind(this);
    this.profile = this.profile.bind(this);
    this.isComponentProfilingEnabled =
      this.isComponentProfilingEnabled.bind(this);
    this.isEventProfilingEnabled = this.isEventProfilingEnabled.bind(this);
    this.isEffectProfilingEnabled = this.isEffectProfilingEnabled.bind(this);
  }

  public captureException(exception: unknown) {}

  public trackEvent(name: string, context?: Record<string, string | number>) {}

  public identify(user: User) {}

  public profile(name: string) {
    return () => {};
  }

  public isComponentProfilingEnabled() {
    if (this.config.profiling === true) {
      return true;
    }
    if (
      Array.isArray(this.config.profiling) &&
      this.config.profiling.includes('component')
    ) {
      return true;
    }
    return false;
  }

  public isEventProfilingEnabled() {
    if (this.config.profiling === true) {
      return true;
    }
    if (
      Array.isArray(this.config.profiling) &&
      this.config.profiling.includes('event')
    ) {
      return true;
    }
    return false;
  }

  public isEffectProfilingEnabled() {
    if (this.config.profiling === true) {
      return true;
    }
    if (
      Array.isArray(this.config.profiling) &&
      this.config.profiling.includes('effect')
    ) {
      return true;
    }
    return false;
  }
}

export class BrowserTelemetry extends Telemetry {
  public captureException(exception: unknown): void {
    this.logger.error(exception);
  }
  public profile(name: string) {
    const end = super.profile(name);
    console.profile(name);
    return () => {
      console.profileEnd(name);
      end();
    };
  }
}
