import React from 'react';
import { CancelledError } from '../classes/error';
import { Callback, Callbacks, FlowControl } from '../classes/flowControl';
import { AppContext } from '../contexts/appContext';
import { useAppContext } from './useAppContext';
import { useIsMounted } from './useIsMounted';
import { useDisabledContext } from './useDisabledContext';

interface EffectContext extends AppContext {
  isMounted: () => boolean;
  abortSignal: AbortSignal;
}

type MaybeCleanup = void | (() => void);

type EffectReturns = MaybeCleanup | Promise<MaybeCleanup>;

export type Effect = (context: EffectContext) => EffectReturns;

export interface EffectOptions extends Partial<Callbacks<unknown>> {
  trackEffect?: boolean;
  profile?: boolean;
  timeout?: number | false;
  bubbleError?: boolean;
  cancelOnCleanup?: boolean;
  disabledSubtree?: boolean;
}

const defaultEffect: (name: string) => Effect = (name) => (context) => () => {
  context.logger.error(`Effect ${name} is missing implementation`);
};

export const useCherreEffect = (
  name: string,
  effect: Effect = defaultEffect(name),
  deps: React.DependencyList = [],
  options: EffectOptions = {}
) => {
  const context = useAppContext();
  const track = options.trackEffect ?? false;
  const profile =
    options.profile ?? context.telemetry.isEffectProfilingEnabled();
  const timeout = options.timeout ?? context.config.timeout;
  const bubbleError = options.bubbleError ?? true;
  const cancelOnCleanup = options.cancelOnCleanup ?? true;
  const disableSubtree = options.disabledSubtree ?? false;

  const isMounted = useIsMounted();

  const [isDisabled, setIsDisabled] = useDisabledContext();

  const [internalError, setInternalError] = React.useState<unknown>(null);

  if (internalError && bubbleError) {
    throw internalError;
  }

  React.useEffect(() => {
    if (isDisabled) {
      return;
    }
    const defaultOnError: Callback<unknown> = (error) => {
      isMounted() && setInternalError(error);
    };
    const flow = new FlowControl(
      {
        name,
        run: (abortSignal) =>
          effect?.({
            ...context,
            isMounted,
            abortSignal,
          }),
        profile,
        timeout,
        track,
        trackContext: {
          breadcrumbs: [...context.breadcrumbs, name].join('::'),
        },
        onCompleted: options.onCompleted,
        onTimeout: options.onTimeout,
        onError: options.onError ?? defaultOnError,
        onCancelled: options.onCancelled,
        beforePromise: () => {
          if (disableSubtree) {
            setIsDisabled(true);
          }
        },
        afterPromise: () => {
          if (disableSubtree) {
            setIsDisabled(false);
          }
        },
      },
      context
    );
    return () => {
      if (cancelOnCleanup) {
        flow.cancel(CancelledError.effectCleanup());
      }
      flow.cleanup();
    };
  }, deps);
};
