import { ConfigConstructors } from '../contexts/configContext';
import { SnackbarHook } from '../types/snackbar';
import { BrowserTelemetry } from '../classes/telemetry';
import { BrowserLogger } from '../classes/log';
import { AppContextConfig } from '../contexts/appContext';

export type ExposeWindowHooks = () => boolean;

type ExternalConfig = {
  exposeWindowHooks: ExposeWindowHooks;
  snackbarHook: SnackbarHook;
};

type InputConfig = ExternalConfig &
  Partial<ConfigConstructors> &
  Partial<AppContextConfig>;

export type Config = ExternalConfig & ConfigConstructors & AppContextConfig;

export function createAppConfig(input: InputConfig): Config {
  return {
    loggerClass: BrowserLogger,
    telemetryClass: BrowserTelemetry,
    logLevel: false,
    profiling: false,
    eventTracking: false,
    loggerHistorySize: 10,
    timeout: 10_000,
    ...input,
  };
}
