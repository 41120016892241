import React, { ProfilerOnRenderCallback } from 'react';
import { useAppContext } from '../hooks/useAppContext';
import { AppContext } from '../contexts/appContext';
import { TimeoutError } from '../classes/error';

const onRender =
  (context: AppContext): ProfilerOnRenderCallback =>
  (
    ReactId,
    phase,
    actualDuration,
    baseDuration,
    startTime,
    commitTime,
    interactions
  ) => {
    context.logger.info({
      ReactId,
      phase,
      actualDuration,
      baseDuration,
      startTime,
      commitTime,
      interactions,
    });
    const { timeout } = context.config;
    if (timeout && actualDuration > timeout) {
      const name = context.breadcrumbs.join('::');
      context.logger.error(new TimeoutError(name, timeout));
    }
  };

export const CherreProfiler: React.FC = ({ children }) => {
  const appContext = useAppContext();
  if (appContext.telemetry.isComponentProfilingEnabled()) {
    return (
      <React.Profiler id={appContext.id} onRender={onRender(appContext)}>
        {children}
      </React.Profiler>
    );
  }
  return <>{children}</>;
};
