import { useEffect, useMemo, useState } from 'react';
import { Profiling, combineProfilingValues } from '../classes/telemetry';
import { ProfilingListener } from '../components/ExtendAppContext';
import { AppContext } from '../contexts/appContext';
import { ConfigContext } from '../contexts/configContext';
import { useEvent_INTERNAL } from './useEvent_INTERNAL';

export const useProfiling_INTERNAL = (
  id: string,
  profiling: Profiling = false,
  configContext: ConfigContext,
  parentContext?: AppContext,
  onProfilingChange?: ProfilingListener,
  disabled = false
) => {
  const [internalProfiling, setProfiling] = useState(
    __DEV__ ? profiling : false
  );

  const changeProfiling = useEvent_INTERNAL((value: Profiling) => {
    setProfiling(value);
    onProfilingChange?.(value);
  });

  useEffect(() => {
    if (disabled) {
      return;
    }
    if (configContext.profilingSetterStore.has(id)) {
      console.error(`ExtendAppContext with ID ${id} is already in use`);
    } else {
      configContext.profilingSetterStore.set(id, changeProfiling);
      return () => {
        configContext.profilingSetterStore.delete(id);
      };
    }
  }, [configContext.profilingSetterStore, changeProfiling, id]);

  if (!parentContext) {
    return internalProfiling;
  }

  return useMemo(
    () =>
      combineProfilingValues(internalProfiling, parentContext.config.profiling),
    [internalProfiling, parentContext.config.profiling]
  );
};
