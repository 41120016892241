import React, { useRef, useContext, useMemo, useState } from 'react';
import { assertLogLevel } from '../classes/log';
import {
  ConfigContext,
  EventTrackingSetterStore,
  LogLevelSetterStore,
  ProfilingSetterStore,
  Store,
  configContext_INTERNAL,
} from '../contexts/configContext';
import { Config } from '../factories/createConfig';
import {
  AppContext,
  AppContextConfig,
  appContext_INTERNAL,
} from '../contexts/appContext';
import { useEventTracking_INTERNAL } from '../hooks/useEventTracking_INTERNAL';
import { useLogLevel_INTERNAL } from '../hooks/useLogLevel_INTERNAL';
import { useLogger_INTERNAL } from '../hooks/useLogger_INTERNAL';
import { useProfiling_INTERNAL } from '../hooks/useProfiling_INTERNAL';
import { useTelemetry_INTERNAL } from '../hooks/useTelemetry_INTERNAL';
import { assertProfilingValue } from '../classes/telemetry';
import { disabledContext_INTERNAL } from '../contexts/disabledContext';

export type AppContextProviderProps = {
  config: Config;
};

// UTILS

function assertRegistered<T>(
  storeRef: React.MutableRefObject<Store<T>>,
  id: string
) {
  if (!storeRef.current.has(id)) {
    throw new Error(`Component with ID ${id} is not registered`);
  }
  return storeRef.current.get(id) as T;
}

function assertBoolean(value: unknown) {
  if (typeof value !== 'boolean') {
    throw new Error(`${value} is not a boolean value`);
  }
  return value;
}

export const AppContextProvider: React.FC<AppContextProviderProps> = ({
  children,
  config,
}) => {
  //check if this is the only AppContextProvider in the tree
  const parentValue = useContext(configContext_INTERNAL);
  if (parentValue) {
    throw new Error(
      'Only one AppContextProvider is allowed within the application'
    );
  }

  //create stores
  const logLevelSetterStoreRef = useRef<LogLevelSetterStore>(new Map());
  const profilingSetterStoreRef = useRef<ProfilingSetterStore>(new Map());
  const eventTrackingSetterStoreRef = useRef<EventTrackingSetterStore>(
    new Map()
  );

  // expose hooks
  const exposeWindowHooks = config.exposeWindowHooks();
  React.useEffect(() => {
    if (__DEV__ || exposeWindowHooks) {
      window.setLogLevel = (logLevel: unknown, id = 'ROOT') => {
        assertRegistered(logLevelSetterStoreRef, id)(assertLogLevel(logLevel));
      };
      window.setProfiling = (profiling: unknown, id = 'ROOT') => {
        assertRegistered(
          profilingSetterStoreRef,
          id
        )(assertProfilingValue(profiling));
      };
      window.setEventTracking = (eventTracking: unknown, id = 'ROOT') => {
        assertRegistered(
          eventTrackingSetterStoreRef,
          id
        )(assertBoolean(eventTracking));
      };
      return () => {
        window.setLogLevel = undefined;
        window.setProfiling = undefined;
        window.setEventTracking = undefined;
      };
    }
  }, [exposeWindowHooks]);

  // create context value
  const configContext = useMemo<ConfigContext>(
    () => ({
      loggerClass: config.loggerClass,
      telemetryClass: config.telemetryClass,
      logLevelSetterStore: logLevelSetterStoreRef.current,
      profilingSetterStore: profilingSetterStoreRef.current,
      eventTrackingSetterStore: eventTrackingSetterStoreRef.current,
    }),
    [
      config.loggerClass,
      config.telemetryClass,
      logLevelSetterStoreRef.current,
      profilingSetterStoreRef.current,
      eventTrackingSetterStoreRef.current,
    ]
  );

  // intial values for appContext
  const logLevel = useLogLevel_INTERNAL('ROOT', config.logLevel, configContext);

  const profiling = useProfiling_INTERNAL(
    'ROOT',
    config.profiling,
    configContext
  );

  const eventTracking = useEventTracking_INTERNAL(
    'ROOT',
    config.eventTracking,
    configContext
  );

  const loggerHistorySize = config.loggerHistorySize;

  const timeout = config.timeout;

  const internalConfig = useMemo<AppContextConfig>(
    () => ({
      logLevel,
      profiling,
      eventTracking,
      loggerHistorySize,
      timeout,
    }),
    [logLevel, profiling, eventTracking, loggerHistorySize, timeout]
  );

  const logger = useLogger_INTERNAL(configContext, internalConfig);

  const telemetry = useTelemetry_INTERNAL(
    configContext,
    internalConfig,
    logger
  );

  const showSnackbar = config.snackbarHook();

  const newContextValue = useMemo<AppContext>(
    () => ({
      config: internalConfig,
      logger,
      telemetry,
      id: 'ROOT',
      breadcrumbs: ['ROOT'],
      showSnackbar,
    }),
    [internalConfig, logger, telemetry, showSnackbar]
  );

  //disabled context value
  const disabledContext = useState(false);

  return (
    <configContext_INTERNAL.Provider value={configContext}>
      <appContext_INTERNAL.Provider value={newContextValue}>
        <disabledContext_INTERNAL.Provider value={disabledContext}>
          {children}
        </disabledContext_INTERNAL.Provider>
      </appContext_INTERNAL.Provider>
    </configContext_INTERNAL.Provider>
  );
};
