import { useContext } from 'react';
import { disabledContext_INTERNAL } from '../contexts/disabledContext';

export const useDisabledContext = () => {
  const disabledContext = useContext(disabledContext_INTERNAL);
  if (!disabledContext) {
    throw new Error(
      'useDisabledContext is being used outside of AppContextProvider'
    );
  }
  return disabledContext;
};
