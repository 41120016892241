import { useEffect, useState } from 'react';
import { EventTrackingListener } from '../components/ExtendAppContext';
import { AppContext } from '../contexts/appContext';
import { ConfigContext } from '../contexts/configContext';
import { useEvent_INTERNAL } from './useEvent_INTERNAL';

export const useEventTracking_INTERNAL = (
  id: string,
  eventTracking = false,
  configContext: ConfigContext,
  parentContext?: AppContext,
  onEventTrackingChange?: EventTrackingListener,
  disabled = false
) => {
  const [internalEventTracking, setEventTracking] = useState(
    __DEV__ ? eventTracking : false
  );

  const changeEventTracking = useEvent_INTERNAL((value: boolean) => {
    setEventTracking(value);
    onEventTrackingChange?.(value);
  });

  useEffect(() => {
    if (disabled) {
      return;
    }
    if (configContext.eventTrackingSetterStore.has(id)) {
      console.error(`ExtendAppContext with ID ${id} is already in use`);
    } else {
      configContext.eventTrackingSetterStore.set(id, changeEventTracking);
      return () => {
        configContext.eventTrackingSetterStore.delete(id);
      };
    }
  }, [configContext.eventTrackingSetterStore, changeEventTracking, id]);

  if (!parentContext) {
    return internalEventTracking;
  }

  return internalEventTracking || parentContext.config.eventTracking;
};
