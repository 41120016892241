import { AppContext } from '../contexts/appContext';
import { useAppContext } from './useAppContext';
import { Callback, Callbacks, FlowControl } from '../classes/flowControl';
import { useEvent_INTERNAL } from './useEvent_INTERNAL';
import { useIsMounted } from './useIsMounted';
import { useDisabledContext } from './useDisabledContext';

export interface EventContext extends AppContext {
  isMounted: () => boolean;
  abortSignal: AbortSignal;
}

export type Event<Params extends Array<any>, R> = (
  context: EventContext
) => (...params: Params) => R | Promise<R>;

export interface EventOptions<T, CTX = AppContext>
  extends Partial<Callbacks<T, CTX>> {
  trackEvent?: boolean;
  profile?: boolean;
  retryButton?: boolean;
  timeout?: number | false;
  chain?: boolean;
  disabledSubtree?: boolean;
}

const safeStringify = (value: unknown) => {
  try {
    return JSON.stringify(value);
  } catch {
    return 'CANNOT STRINGIFY';
  }
};

const defaultEvent: (name: string) => Event<any, void> =
  (name: string) => (context) => () => {
    context.showSnackbar({
      type: 'error',
      message:
        'This action is not implemented yet, contact cherre support for an ETA',
    });
    context.logger.error(`CTA ${name} is missing implementation`);
  };

const defaultOnErrorCreator: (
  onClick: () => void,
  retryButton: boolean
) => Callback<unknown> = (onClick, retryButton) => (error, context) => {
  context.showSnackbar({
    type: 'error',
    message: 'Something went wrong',
    buttons: retryButton
      ? [
          {
            title: 'try again',
            type: 'primary',
            onClick,
          },
        ]
      : undefined,
  });
  return undefined as any;
};

/**
 * @param name the name of the event used for telemetry
 * @param event the function to be called when the event is triggered
 * @param options options for the event, such as onError
 * @returns a function that triggers the event
 *
 * @example
 * const myEventTrigger = useCherreEvent('myEvent', async (param: string, param2: number) => {
 *   // do something
 * })
 *
 * // When the button is clicked, the event will be triggered and the function passed to useCherreEvent will be called,
 * // a telemetry event will be tracked with the name 'myEvent' with the parameters 'param' and '2'
 * // if the function throws an error, a snackbar will be shown with the message 'Something went wrong'
 * <Button onClick={() => myEventTrigger('param', 2)}>Trigger</Button>
 */
export const useCherreEvent = <P, Params extends P[], R>(
  name: string,
  event: (...params: Params) => R | Promise<R>,
  options?: {
    onError?: () => void;
  }
) => {
  const ctx = useAppContext();
  return useEvent_INTERNAL(async (...params: Params) => {
    ctx.telemetry.trackEvent(name, {
      params: safeStringify(params),
      breadcrumbs: [...ctx.breadcrumbs, name].join('::'),
    });
    try {
      return await event(...params);
    } catch (error) {
      if (options?.onError) {
        options.onError();
      } else {
        ctx.logger.warn(`Error in event ${name}`);
        ctx.logger.error(error);
        ctx.showSnackbar({
          type: 'error',
          message: 'Something went wrong',
        });
      }
    }
  });
};

/**
 * This function is deprecated because it can cause side effects that are hard to track, use useCherreEvent instead when possible
 * @deprecated
 */
export const useCherreEventWithFlow = <P, Params extends P[], R>(
  name: string,
  event: Event<Params, R> = defaultEvent(name) as Event<Params, R>,
  options: EventOptions<R> = {}
): ((...params: Params) => void | Promise<void>) => {
  const context = useAppContext();
  const track = options.trackEvent ?? context.config.eventTracking;
  const profile =
    options.profile ?? context.telemetry.isEventProfilingEnabled();
  const timeout = options.timeout ?? context.config.timeout;
  const retryButton = options.retryButton ?? false;
  const chain = options.chain ?? true;
  const disableSubtree = options.disabledSubtree ?? true;

  const isMounted = useIsMounted();

  const [isDisabled, setIsDisabled] = useDisabledContext();

  const cbk = useEvent_INTERNAL<P, any, Params>((...params) => {
    if (isDisabled) {
      console.warn(`Event ${name} is disabled`);
      return;
    }
    const defaultOnError = defaultOnErrorCreator(
      () => cbk(...params),
      retryButton
    );
    const flow: FlowControl<R> = new FlowControl(
      {
        name,
        run: (abortSignal) =>
          event?.({
            ...context,
            isMounted,
            abortSignal,
          })(...params),
        profile,
        timeout,
        track,
        trackContext: {
          params: safeStringify(params),
          breadcrumbs: [...context.breadcrumbs, name].join('::'),
        },
        chain,
        onCompleted: options.onCompleted,
        onTimeout: options.onTimeout,
        onError: options.onError ?? defaultOnError,
        beforePromise: () => {
          if (disableSubtree) {
            setIsDisabled(true);
          }
        },
        afterPromise: () => {
          if (disableSubtree) {
            setIsDisabled(false);
          }
        },
      },
      context
    );
    return flow.wait();
  });
  return cbk;
};
