import { useEffect, useState } from 'react';
import { LogLevel, combineLogLevels } from '../classes/log';
import { LogLevelListener } from '../components/ExtendAppContext';
import { AppContext } from '../contexts/appContext';
import { ConfigContext } from '../contexts/configContext';
import { useEvent_INTERNAL } from './useEvent_INTERNAL';

export const useLogLevel_INTERNAL = (
  id: string,
  logLevel: LogLevel = false,
  configContext: ConfigContext,
  parentContext?: AppContext,
  onLogLevelChange?: LogLevelListener,
  disabled = false
) => {
  const [internalLevel, setLevel] = useState(__DEV__ ? logLevel : false);

  const changeLogLevel = useEvent_INTERNAL((value: LogLevel) => {
    setLevel(value);
    onLogLevelChange?.(value);
  });

  useEffect(() => {
    if (disabled) {
      return;
    }
    if (configContext.logLevelSetterStore.has(id)) {
      console.error(`ExtendAppContext with ID ${id} is already in use`);
    } else {
      configContext.logLevelSetterStore.set(id, changeLogLevel);
      return () => {
        configContext.logLevelSetterStore.delete(id);
      };
    }
  }, [configContext.logLevelSetterStore, changeLogLevel, id]);

  if (!parentContext) {
    return internalLevel;
  }

  return combineLogLevels(internalLevel, parentContext.config.logLevel);
};
