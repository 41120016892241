import React from 'react';
import { LogLevel, LoggerConstructor } from '../classes/log';
import { Profiling, TelemetryConstructor } from '../classes/telemetry';

export type LogLevelSetter = (logLevel: LogLevel) => void;
export type ProfilingSetter = (profiling: Profiling) => void;
export type EventTrackingSetter = (enabled: boolean) => void;

export type ConfigConstructors = {
  loggerClass: LoggerConstructor;
  telemetryClass: TelemetryConstructor;
};

export type Store<T> = Map<string, T>;

export type LogLevelSetterStore = Store<LogLevelSetter>;
export type ProfilingSetterStore = Store<ProfilingSetter>;
export type EventTrackingSetterStore = Store<EventTrackingSetter>;

export type ConfigSetterStores = {
  logLevelSetterStore: LogLevelSetterStore;
  profilingSetterStore: ProfilingSetterStore;
  eventTrackingSetterStore: EventTrackingSetterStore;
};

export type ConfigContext = ConfigConstructors & ConfigSetterStores;

export const configContext_INTERNAL = React.createContext<ConfigContext | null>(
  null
);
