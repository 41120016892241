import React, { useContext, useMemo, useState } from 'react';
import { LogLevel } from '../classes/log';
import { Profiling } from '../classes/telemetry';
import {
  AppContext,
  AppContextConfig,
  appContext_INTERNAL,
} from '../contexts/appContext';
import { configContext_INTERNAL } from '../contexts/configContext';
import { useEventTracking_INTERNAL } from '../hooks/useEventTracking_INTERNAL';
import { useLogLevel_INTERNAL } from '../hooks/useLogLevel_INTERNAL';
import { useProfiling_INTERNAL } from '../hooks/useProfiling_INTERNAL';
import { CherreProfiler } from './CherreProfiler';
import { useLogger_INTERNAL } from '../hooks/useLogger_INTERNAL';
import { useTelemetry_INTERNAL } from '../hooks/useTelemetry_INTERNAL';
import { useDisabledContext } from '../hooks/useDisabledContext';
import { disabledContext_INTERNAL } from '../contexts/disabledContext';
import { useIsMounted } from '../hooks/useIsMounted';

type Listener<T> = (newValue: T) => void;

export type LogLevelListener = Listener<LogLevel>;
export type ProfilingListener = Listener<Profiling>;
export type EventTrackingListener = Listener<boolean>;

type ReadonlyValues =
  | 'logger'
  | 'telemetry'
  | 'showSnackbar'
  | 'id'
  | 'breadcrumbs'
  | 'config';

export type AppContextExtension = Partial<Omit<AppContext, ReadonlyValues>>;

export type ExtendAppContextProps = {
  id: string;
  extend?: AppContextExtension;
  config?: Partial<AppContextConfig>;
  disableParent?: boolean;
  onLogLevelChange?: LogLevelListener;
  onProfilingChange?: ProfilingListener;
  onEventTrackingChange?: EventTrackingListener;
  style?: React.CSSProperties;
  className?: string;
};

export const ExtendAppContext: React.FC<ExtendAppContextProps> = ({
  id,
  extend,
  config,
  disableParent = false,
  children,
  onLogLevelChange,
  onProfilingChange,
  onEventTrackingChange,
  style,
  className,
}) => {
  const parentContext = useContext(appContext_INTERNAL);
  const configContext = useContext(configContext_INTERNAL);
  if (!parentContext || !configContext) {
    throw new Error(
      'ExtendAppContext is being used outside AppContextProvider'
    );
  }

  const isMounted = useIsMounted();

  const [parentDisabledValue, setParentDisabledValue] = useDisabledContext();
  const [myDisabledValue, setMyDisabledValue] = useState(false);
  const disabledContextValue = useMemo(() => {
    const setDisabled = (disabled: boolean) => {
      if (disableParent) {
        setParentDisabledValue(disabled);
      }
      if (isMounted()) {
        setMyDisabledValue(disabled);
      }
    };
    const finalDisabledValue = parentDisabledValue || myDisabledValue;
    return [finalDisabledValue, setDisabled] as const;
  }, [parentDisabledValue, myDisabledValue, setMyDisabledValue]);

  const logLevel = useLogLevel_INTERNAL(
    id,
    config?.logLevel,
    configContext,
    parentContext,
    onLogLevelChange,
    parentDisabledValue
  );

  const profiling = useProfiling_INTERNAL(
    id,
    config?.profiling,
    configContext,
    parentContext,
    onProfilingChange,
    parentDisabledValue
  );

  const eventTracking = useEventTracking_INTERNAL(
    id,
    config?.eventTracking,
    configContext,
    parentContext,
    onEventTrackingChange,
    parentDisabledValue
  );

  const loggerHistorySize =
    config?.loggerHistorySize || parentContext.config.loggerHistorySize;

  const timeout = config?.timeout || parentContext.config.timeout;

  const internalConfig = useMemo<AppContextConfig>(
    () => ({
      logLevel,
      profiling,
      eventTracking,
      loggerHistorySize,
      timeout,
    }),
    [logLevel, profiling, eventTracking, loggerHistorySize, timeout]
  );

  const logger = useLogger_INTERNAL(configContext, internalConfig);

  const telemetry = useTelemetry_INTERNAL(
    configContext,
    internalConfig,
    logger
  );

  const newContextValue = useMemo<AppContext>(
    () => ({
      ...parentContext,
      ...extend,
      //overide the readonly configs
      config: internalConfig,
      logger,
      telemetry,
      id,
      breadcrumbs: [...parentContext.breadcrumbs, id],
      showSnackbar: parentContext.showSnackbar,
    }),
    [parentContext, internalConfig, logger, telemetry, extend]
  );

  return (
    <appContext_INTERNAL.Provider value={newContextValue}>
      <disabledContext_INTERNAL.Provider value={disabledContextValue}>
        <CherreProfiler>
          <div data-app-context-id={id} style={style} className={className}>
            {children}
          </div>
        </CherreProfiler>
      </disabledContext_INTERNAL.Provider>
    </appContext_INTERNAL.Provider>
  );
};
