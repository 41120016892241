import { useMemo, useRef } from 'react';
import { LogHistory } from '../classes/log';
import { AppContextConfig } from '../contexts/appContext';
import { ConfigContext } from '../contexts/configContext';

export const useLogger_INTERNAL = (
  configContext: ConfigContext,
  config: AppContextConfig
) => {
  const logHistoryRef = useRef<LogHistory>([]);
  return useMemo(
    () => new configContext.loggerClass(config, logHistoryRef),
    [configContext, config]
  );
};
