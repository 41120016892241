import { AppContext } from '../contexts/appContext';
import { CancelledError, TimeoutError } from './error';

const createTimeout = (onTimeout: () => void, ms: number) => {
  const handle = setTimeout(onTimeout, ms);
  return () => clearTimeout(handle);
};

export type Callback<T, CTX = AppContext> = (value: T, ctx: CTX) => any;

export type Callbacks<T, CTX = AppContext> = {
  onCompleted: Callback<T, CTX>;
  onError: Callback<unknown, CTX>;
  onTimeout: Callback<TimeoutError, CTX>;
  onCancelled: Callback<CancelledError, CTX>;
};

export type Runner<T> = (signal: AbortSignal) => T | Promise<T>;

export interface FlowControlOptions<T> extends Partial<Callbacks<T>> {
  name: string;
  track?: boolean;
  profile?: boolean;
  timeout?: number | false;
  trackContext?: Record<string, string | number>;
  chain?: boolean;
  run: Runner<T>;
  // before promise and after promise are callbacks to run any side effects of having a promise
  beforePromise?: () => void;
  afterPromise?: () => void;
}

export class FlowControl<T> {
  private finished = false;
  private abortController = new AbortController();
  private clearTimer: (() => void) | null = null;
  private endProfile: (() => void) | null = null;
  private cleanupfn: (() => void) | null = null;
  private resolve: (() => void) | null = null;
  private wrapperPromise: Promise<void> | null = null;

  /**
   * this class coordinates a async flow for events and effects
   * the flow is the following:
   * 1 - if the event should be tracked we call track event
   * 2 - if the event has a timeout we create the timeout to call the "timeout" method and save the clear timeout callback
   * 3 - if the event should be profiled we start the profile and save the end profile callback
   * 4 - we run the event passing it the abort signal to manage cancel and timeout handlers
   * 5 - if the event returns a promise:
   *  5.1 - we run the beforePromise callback, it is not exactly before it starts but right after, should be fine
   *  5.2 - we create a wrapper promise to be delivered to the caller
   *  5.3 - we attach the "completed", "error" and afterPromise callbacks to it
   * 6 - else, we call the "completed" callback since the event is synchronous
   * 7 - if anything errors we call the "error" callback
   * @param options
   * @param context
   */
  constructor(
    private options: FlowControlOptions<T>,
    private context: AppContext
  ) {
    try {
      if (this.options.track) {
        this.context.telemetry.trackEvent(
          this.options.name,
          this.options.trackContext
        );
      }
      if (this.options.timeout) {
        this.clearTimer = createTimeout(
          () => this.timeout(),
          this.options.timeout
        );
      }
      if (this.options.profile) {
        this.endProfile = this.context.telemetry.profile(this.options.name);
      }
      const result = this.options.run(this.abortController.signal);
      if (result instanceof Promise) {
        this.options.beforePromise?.();
        this.createWrapperPromise().finally(this.options.afterPromise);
        result.then(this.completed).catch(this.error);
      } else {
        this.completed(result);
      }
    } catch (e) {
      this.error(e);
    }
  }

  // UTILITIES

  /**
   * create the wrapper promise to be delivered to the caller and saves the resolve callback to be resolved on any callback
   */
  private createWrapperPromise = () => {
    this.wrapperPromise = new Promise<void>((resolve) => {
      this.resolve = resolve;
    });
    return this.wrapperPromise;
  };

  /**
   * handles the case where we need to await to another async function before resolving the wrapper promise (i.e. chaning events)
   */
  private waitForChain = async (result: unknown) => {
    if (result instanceof Promise && this.options.chain) {
      await result;
    }
    this.resolve?.();
  };

  /**
   * this method wraps up the effect, if it has already been called (finished = true) it prevents re-doing any of the work
   * else, it will call the end profile if there is one, and clear timer if there is one
   * finally it will call the "finally" options for any actions that need to happen no matter the result
   * and also call the right callback to handle the result (each resolver will have it's own handler, that will be passed to finalize to run)
   */
  private finalize = async (cbk: () => void) => {
    if (this.finished) {
      return;
    }
    this.finished = true;
    this.endProfile?.();
    this.clearTimer?.();
    cbk();
  };

  // RESOLVERS

  /**
   * this is called when the run was succesful,
   * if the run returns a function we consider it a cleanup effect, and save it
   * we call the onCompleted callback with the result
   */
  private completed = async (result: T) => {
    this.finalize(() => {
      if (typeof result === 'function') {
        this.cleanupfn = result as any;
      }
      this.waitForChain(this.options.onCompleted?.(result, this.context));
    });
  };

  /**
   * this is called when the run failed
   * we call the capture exception method with the error
   * and then call the onError callback
   */
  private error = async (error: unknown) => {
    this.finalize(() => {
      this.context.telemetry.captureException(error);
      this.waitForChain(this.options.onError?.(error, this.context));
    });
  };

  /**
   * this callback will only be called if timeout is configured,
   * it create the TimeoutError instance
   * calls the abort controller so the event can handle being aborted
   * calls capture exception with the timeout error
   * calls the "onTimeout" handler with the error
   */
  private timeout = async () => {
    const timeoutValue = this.options.timeout;
    if (timeoutValue) {
      this.finalize(() => {
        const error = new TimeoutError(this.options.name, timeoutValue);
        this.abortController.abort(error);
        this.context.telemetry.captureException(error);
        this.waitForChain(this.options.onTimeout?.(error, this.context));
      });
    }
  };

  /**
   * this will be called when the "cancel" function is called on the flow controller
   * it fires the abort signal so the event can handle being aborted
   * calls the capture exception with the cancelled error instance
   * calls the "onCancelled" handler with the error
   */
  private cancelled = async (error: CancelledError) => {
    this.finalize(() => {
      this.abortController.abort(error);
      this.context.telemetry.captureException(error);
      this.waitForChain(this.options.onCancelled?.(error, this.context));
    });
  };

  /**
   * method to manually cancel the event execution
   */
  public cancel = (error: CancelledError) => {
    this.cancelled(error);
  };

  /**
   * this method returns the wrapper function to the caller
   */
  public wait = (): Promise<void> | void => {
    if (this.wrapperPromise) {
      return this.wrapperPromise;
    }
  };

  /**
   * this method executes a cleanup function if there is one
   */
  public cleanup = () => () => this.cleanupfn?.();
}
