import { useMemo } from 'react';
import { Logger } from '../classes/log';
import { AppContextConfig } from '../contexts/appContext';
import { ConfigContext } from '../contexts/configContext';

export const useTelemetry_INTERNAL = (
  configContext: ConfigContext,
  config: AppContextConfig,
  logger: Logger
) => {
  return useMemo(
    () => new configContext.telemetryClass(logger, config),
    [configContext, config]
  );
};
