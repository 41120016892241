import { v4 as uuid } from 'uuid';

export class CherreError extends Error {
  private internalId = uuid();
  constructor(message = 'Something went wrong') {
    super(message);
    this.getErrorId = this.getErrorId.bind(this);
  }
  public getErrorId() {
    return this.internalId;
  }
}

export class TimeoutError extends CherreError {
  constructor(name: string, timeout: number) {
    super(`"${name}" ran for over than ${timeout} ms`);
  }
}

export class CancelledError extends CherreError {
  private constructor(message: string) {
    super(message);
  }

  static effectCleanup = () => {
    return new CancelledError('Cancelled due to effect cleanup');
  };
}
