import { useContext } from 'react';
import { appContext_INTERNAL } from '../contexts/appContext';

export const useAppContext = () => {
  const appContext = useContext(appContext_INTERNAL);
  if (!appContext) {
    throw new Error(
      'useAppContext is being used outside of AppContextProvider'
    );
  }
  return appContext;
};
