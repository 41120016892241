import React from 'react';
import { AppContextConfig } from '../contexts/appContext';

// CONSTANTS

const LOG_LEVEL_ORDER = [
  true /* everything is on */,
  'log',
  'info',
  'debug',
  'warn',
  'error',
  false /* everything is off */,
] as const;

// EXTERNAL TYPES

export type LogLevel = (typeof LOG_LEVEL_ORDER)[number];

export interface LoggerConstructor {
  new (config: AppContextConfig, historyRef?: LogHistoryRef): Logger;
}

export type LogEntry = {
  level: Exclude<LogLevel, boolean>;
  params: unknown[];
  timestamp: Date;
};

export type LogHistory = LogEntry[];

export type LogHistoryRef = React.MutableRefObject<LogHistory>;

// EXTERNAL UTILITY FUNCTIONS

/**
 * isLogLevel can be used to verify if a unknown variable is a valid log level
 * @param {unknown} value - the variable to be verified
 * @returns {boolean} true if value is a valid log level, false otherwise
 */
export function isLogLevel(value: unknown): value is LogLevel {
  return (LOG_LEVEL_ORDER as ReadonlyArray<unknown>).includes(value);
}

export const assertLogLevel = (value: unknown) => {
  if (!isLogLevel(value)) {
    throw new Error(`${value} is not a valid log level value`);
  }
  return value;
};

export const combineLogLevels = (
  logLevel1: LogLevel,
  logLevel2: LogLevel
): LogLevel => {
  const level1Order = LOG_LEVEL_ORDER.indexOf(logLevel1);
  const level2Order = LOG_LEVEL_ORDER.indexOf(logLevel2);
  //get the most permissive one
  const minOrder = Math.min(level1Order, level2Order);
  return LOG_LEVEL_ORDER[minOrder];
};

// UTILITY FUNCTIONS

const print = (e: LogEntry) =>
  console[e.level](`HISTORY::${e.timestamp}::`, ...e.params);

const allowedChecker = (currentLevel: LogLevel) => {
  const levelNumber = LOG_LEVEL_ORDER.indexOf(currentLevel);
  const allowedLevels = LOG_LEVEL_ORDER.slice(levelNumber);
  return (level: LogLevel) => allowedLevels.includes(level);
};

// LOGGER

/**
 * Abstract class to the Logger API
 * @abstract
 */
export abstract class Logger {
  // keeping things in history might prevent garbage collection
  // should we stringify before adding to history?
  constructor(
    private config: AppContextConfig,
    private historyRef?: LogHistoryRef
  ) {
    assertLogLevel(config.logLevel);
    this.addEntry = this.addEntry.bind(this);
    this.log = this.log.bind(this);
    this.info = this.info.bind(this);
    this.debug = this.debug.bind(this);
    this.warn = this.warn.bind(this);
    this.error = this.error.bind(this);
    this.dump = this.dump.bind(this);
    this.getLogLevel = this.getLogLevel.bind(this);
  }

  private addEntry(level: Exclude<LogLevel, boolean>, params: unknown[]) {
    if (this.historyRef) {
      this.historyRef.current.push({ level, params, timestamp: new Date() });
      this.historyRef.current = this.historyRef.current.slice(
        -this.config.loggerHistorySize
      );
    }
  }

  public log(...params: unknown[]) {
    this.addEntry('log', params);
  }

  public info(...params: unknown[]) {
    this.addEntry('info', params);
  }

  public debug(...params: unknown[]) {
    this.addEntry('debug', params);
  }

  public warn(...params: unknown[]) {
    this.addEntry('warn', params);
  }

  public error(...params: unknown[]) {
    this.addEntry('error', params);
  }

  public dump() {
    return this.historyRef?.current;
  }

  public getLogLevel() {
    return this.config.logLevel;
  }

  public isAllowed = allowedChecker(this.config.logLevel);
}

/**
 * Concrete implementation of a Logger with the browser console API
 * @class
 */
export class BrowserLogger extends Logger {
  constructor(config: AppContextConfig, historyRef?: LogHistoryRef) {
    const isAllowed = allowedChecker(config.logLevel);
    if (historyRef) {
      // separate history:
      const h = historyRef.current;
      const allowedHistory = h.filter(({ level }) => isAllowed(level));
      const unallowedHistory = h.filter(({ level }) => !isAllowed(level));
      // print allowed history:
      allowedHistory.forEach(print);
      // add back to history the ones not allowed
      historyRef.current = unallowedHistory;
    }
    super(config, historyRef);
    // enable allowed methods
    if (isAllowed('log')) {
      this.log = console.log;
    }
    if (isAllowed('info')) {
      this.info = console.info;
    }
    if (isAllowed('debug')) {
      this.debug = console.debug;
    }
    if (isAllowed('warn')) {
      this.warn = console.warn;
    }
    if (isAllowed('error')) {
      this.error = console.error;
    }
  }
}
